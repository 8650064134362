import api from "./api";

export default {
  list: function () {
    return api.get("/suggestion");
  },
  get: function (id) {
    return api.get(`/suggestion/${id}`);
  },
  markAsRead: function (id) {
    return api.put(`/suggestion/markAsRead/${id}`);
  },
  save: function (suggestion) {
    return api.post("/suggestion", suggestion);
  },
};
