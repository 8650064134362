import React from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { SectionType } from '../common/constants/tenant-constants';


const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 10%",
  },
  title: {
    margin: '35px auto 25px',
  },
  description: {
    marginBottom: '60px',
  },
  bold: {
    fontWeight: 'bold',
    color: '#888',
  },
}))


const Home = () => {
  const { sections } = useSelector(state => state.root);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container} >
      <Typography
        variant="h1"
        className={classes.title}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(sections[SectionType.HOME_TITLE] ?? ""),
        }}
      />
      <Typography
        variant="subtitle1"
        className={classes.description}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(sections[SectionType.HOME_DESCRIPTION] ?? ""),
        }}
      />

      <Grid container spacing={4} style={{ maxWidth: '1000px' }} >
        <Grid item xs={12} >
          <Typography variant="h5" ><span className={classes.bold} >{t('home.actions_question')}</span></Typography>
        </Grid>
        <Grid item xs={12} sm={4} >
          <Button
            component={Link}
            to="/nueva-denuncia"
            fullWidth
            color="primary"
            variant="contained" >{t('home.issue_complaint')}</Button>
        </Grid>
        <Grid item xs={12} sm={4} >
          <Button
            component={Link}
            to="/nueva-sugerencia"
            fullWidth
            color="primary"
            variant="contained" >{t('home.issue_suggestion')}</Button>
        </Grid>
        <Grid item xs={12} sm={4} >
          <Button
            component={Link}
            to="/casilla-segura-usuario"
            fullWidth
            color="primary"
            variant="outlined" >{t('home.check_complaint')}</Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;