import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';

import { complaints } from '../api';
import { showErrorToast, showSuccessToast } from '../utils/showToast';

import ComplaintForm from '../components/complaintForm/ComplaintForm';
import ComplaintContactForm from '../components/complaintForm/ComplaintContactForm';
import ComplaintConfirmation from '../components/complaintForm/ComplaintConfirmation';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  container: {
    padding: '60px 10%',
  },
  title: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  navigationButtons: {
    marginTop: '35px'
  },
  form: {
    paddingTop: '50px'
  },
  loader: {
    marginTop: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: '60px'
    },
  }
}))

const NewComplaint = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'new_complaint' });
  const steps = [
    {
      name: t('step_1'),
      Component: ComplaintForm,
    },
    {
      name: t('step_2'),
      Component: ComplaintContactForm,
    },
    {
      name: t('step_3'),
      Component: ComplaintConfirmation,
    },
  ]
  const [isLoading, setIsLoading] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [creationsResponse, setCreationsResponse] = useState(null);
  const CurrentStep = steps[activeStepIndex];
  const classes = useStyles();
  const methods = useForm();

  useEffect(() => {
    if (methods.getValues('acceptPolicy')) {
      setAcceptedPolicy(true);
      return;
    };
    setAcceptedPolicy(false)
  }, [methods.watch('acceptPolicy')])
  
  const submit = async complaint => {
    if (activeStepIndex === steps.length -2) {
      try {
        setIsLoading(true)
        const { data } = await complaints.save(complaint);
        setCreationsResponse(data);
        showSuccessToast("La denuncia fue guardada con éxito.")
        setIsLoading(false);
      } catch (error) {
        showErrorToast("Hubo un problema al guardar la denuncia. Por favor inténtelo de nuevo")
        setIsLoading(false);
        return;
      }
    }
    nextStep();
  };
  
  const prevStep = () => setActiveStepIndex(prev => prev -1);
  const nextStep = () => setActiveStepIndex(prev => prev +1);

  return (
    <Container maxWidth="lg" className={classes.container} >
      <Typography variant="h1" className={classes.title} >{t('title')}</Typography>
      <Stepper activeStep={activeStepIndex} alternativeLabel>
        {steps.map(step => 
          <Step key={step.name}>
            <StepLabel>{step.name}</StepLabel>
          </Step>
        )}
      </Stepper>
      {isLoading
        ? <Grid container justifyContent="center" className={classes.loader} ><CircularProgress /></Grid>
        : activeStepIndex === steps.length -1
          ? <CurrentStep.Component {...creationsResponse} />
          : <FormProvider {...methods} >
              <form className={classes.form} onSubmit={methods.handleSubmit(submit)} >

                <CurrentStep.Component {...creationsResponse} />

                {activeStepIndex !== steps.length -1 &&
                  <Grid spacing={2} container justifyContent="space-between" className={classes.navigationButtons} >
                    <Grid item xs={12} sm={5} md={3} >
                      <Button onClick={prevStep} disabled={activeStepIndex === 0} fullWidth variant="outlined" color="primary" >{t('go_back_button')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3} >
                      <Button type="submit"
                        disabled={activeStepIndex === 1 && !acceptedPolicy}
                        fullWidth variant="contained" color="primary" >
                          {activeStepIndex === steps.length -1 ? t('save_suggestion') : t('go_next_button')}
                      </Button>
                    </Grid>
                  </Grid>
                }
              </form>
            </FormProvider>
      }
    </Container>
  );
}

export default NewComplaint;